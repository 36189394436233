import { Controller } from '@hotwired/stimulus'
import ahoy from 'ahoy.js'

export default class extends Controller {
  static values = {
    trackableId: String,
    trackableType: String,
    trackableEvent: { type: String, default: 'Show' }
  }

  initialize () {
    ahoy.configure({
      cookies: false
    })
  }

  connect () {
    if (this.hasTrackableTypeValue && this.hasTrackableEventValue && this.hasTrackableIdValue) {
      const ahoyEvent = [this.trackableTypeValue, this.trackableEventValue, this.trackableIdValue].join('-')
      ahoy.track(`${ahoyEvent}`, {
        trackableType: this.trackableTypeValue,
        trackableId: this.trackableIdValue,
        trackableEvent: this.trackableEventValue
      })
    }
  }
}
