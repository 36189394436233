import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['source', 'target']

  parameterize () {
    const replaceMap = {
      ae: /ä/,
      oe: /ö/,
      ue: /ü/,
      ss: /ß/,
      '-': /[^a-z0-9]+/gi, // global and case insensitive matching of non-char/non-numeric
      '': /^-*|-*$/g // get rid of any leading/trailing dashes
    }

    let text = this.sourceTarget.value.toLowerCase()

    for (const newSubstr in replaceMap) {
      text = text.replace(replaceMap[newSubstr], newSubstr)
    }

    this.targetTarget.value = text
  }
}
