import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select/dist/js/tom-select.complete'

export default class extends Controller {
  static values = { 'remove-title': { type: String, default: 'Tag entfernen' } }

  connect () {
    /* eslint-disable no-new */
    new TomSelect(this.element, {
      plugins: {
        remove_button: {
          title: this.removeTitleValue
        }
      },
      persist: false,
      create: false
    })
  }
}
