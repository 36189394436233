import { Controller } from '@hotwired/stimulus'
import Typed from 'typed.js'

export default class extends Controller {
  static targets = ['element']
  static values = { strings: Array }

  connect () {
    /* eslint-disable no-new */
    new Typed(this.elementTarget, {
      strings: this.stringsValue,
      typeSpeed: 200,
      backSpeed: 100,
      smartBackspace: true,
      loop: true,
      backDelay: 1000,
      showCursor: true,
      cursorChar: '│',
      autoInsertCss: true
    })
  }
}
