import { Controller } from '@hotwired/stimulus'
import EasyMDE from 'easymde'

export default class extends Controller {
  connect () {
    /* eslint-disable no-new */
    new EasyMDE({
      element: this.element,
      initialValue: this.element.value,
      autoDownloadFontAwesome: false,
      spellChecker: false,
      toolbar: [
        'heading', 'bold', 'italic', '|',
        'quote', 'unordered-list', 'ordered-list', '|',
        'link', 'table', 'horizontal-rule', '|',
        'preview', 'side-by-side', 'fullscreen', '|',
        {
          name: 'link',
          action: 'https://guides.github.com/features/mastering-markdown/',
          className: 'fa fab fa-github',
          title: 'Markdown formatting syntax'
        }
      ]
    })
  }
}
