import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  focus () {
    const target = this.element.querySelector(arguments[0])
    if (target) target.focus()
  }

  goto () {
    window.location.href = arguments[0]
  }
}
