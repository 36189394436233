import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['source', 'message']
  static values = {
    success: { type: String, default: 'Copied!' },
    successDuration: { type: Number, default: 2000 },
    activeClass: { type: String, default: 'btn-success' },
    inactiveClass: { type: String, default: 'btn-outline-secondary' }
  }

  connect () {
    this.originalHTML = this.messageTarget.innerHTML
  }

  copy () {
    this.sourceTarget.select()
    document.execCommand('copy')
    this.copied()
  }

  copied () {
    this.messageTarget.innerHTML = this.successValue
    this.messageTarget.classList.remove(this.inactiveClassValue)
    this.messageTarget.classList.add(this.activeClassValue)

    this.timeout = setTimeout(() => {
      this.messageTarget.classList.remove(this.activeClassValue)
      this.messageTarget.classList.add(this.inactiveClassValue)
      this.messageTarget.innerHTML = this.originalHTML
    }, this.successDurationValue)
  }
}
