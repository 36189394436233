import { Controller } from '@hotwired/stimulus'
import hljs from 'highlight.js'
import CopyButtonPlugin from 'highlightjs-copy'

export default class extends Controller {
  static values = {
    cssSelector: { type: String, default: 'pre code' }
  }

  connect () {
    const codeBlocksWithoutHighlight = this.element.querySelectorAll(`${this.cssSelectorValue}:not([data-highlighted])`)
    if (codeBlocksWithoutHighlight.length === 0) return
    hljs.addPlugin(new CopyButtonPlugin())
    hljs.default.highlightAll()
  }
}
